.pricecard .pricecardhead:hover {
  transform: scale(1.04);
  box-shadow: 0px 5px 7px -6px rgba(0, 0, 0, 0.08),
    0px 8px 13.5px -6px rgba(0, 0, 0, 0.12),
    0px 16px 36px -6px rgba(0, 0, 0, 0.15);
}
.pricecard:nth-of-type(1) .pricecardhead {
  background: #ffffff;
}
.pricecard3 {
    background: #5b32e2f7 !important;
    color: #fff !important;
  }

  .custom-btn{
    background: #764dfff2;
    border: none;
}
.pricecard .pricecardhead {
  padding: 1.5rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-in-out;
  color: #787474;
  border: 1px solid #ebebeb;
  cursor: pointer;
  box-shadow: 1px 1px 9px 0px rgba(22, 8, 43, 0.1);
}
.pricecardhead h2{
    color: #000;
}
.sub {
    line-height: 1.25;
    margin-bottom: 0.9rem !important;
    
  }
  .btnbuy{
    border-radius: 99px !important;
    border: 1px solid #D9D6F3;
    padding: 13px;
    /* width: 138px; */
    height: 48px;
    font-family: sans-serif;
   
    font-weight: 600 !important;

    background: #8072D5 !important;
    color: #fff !important;

  
  }
  .pricecard h2 {
    font-weight: 600;
    font-size: 1.25rem;
  }
  .pricecard .price {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.25;
    color: black;
    margin-top: auto;
    
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #444444;
    margin-top: 20px;
  }
  .details .details-row {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 1.5rem 1.5rem;
    border-bottom: 1px solid #ececec;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    color: #797979;
  }
  .credittext{
    display: flex;
    justify-content: end;
    font-weight: 600;
  }

  .pricecard .pricecardpayment {
    padding: 1.5rem;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    height: 18rem;
    display: flex;
    flex-direction: column;
    transition: all 0.25s ease-in-out;
    color: #787474;
    border: 1px solid #ebebeb;
    cursor: pointer;
    box-shadow: 1px 1px 9px 0px rgba(22, 8, 43, 0.1);
}

._failed{ border-bottom: solid 4px red !important; }
._failed i{  color:red !important;  }

._success {
  border: 1px solid #ebebeb;
    border-radius: 1rem;
    box-shadow: 1px 1px 9px 0 #16082b1a;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #8072d5;
}

._success i {
    font-size: 55px;
    color: #8072d5;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
    margin-bottom: 7px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}
.homepage{
  background-color: #8072d5;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
    text-decoration: none;
    font-weight: 600;
}
.confirmation-footer {
  margin-top: 16px;
}
.retry-link {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  color: #fff !important;
  background-color: #8072d5;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
}

.retry-link:hover {
  background-color: #7e71d2;
}

._failed {
  border-bottom: solid 4px #8072d5 !important;
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.icon-shake {
  animation: shake 0.5s ease-in-out;
}


