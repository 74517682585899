h1,h2,h3,h4,h5,h6,p,a{
    font-family: 'Poppins';
}
@media only screen and (min-width: 480px) {
.contact_bg{
    background: url(../../public/assets/image/contact_bg.png),#D9D6F3;
    background-repeat: no-repeat;
    background-size: 354px;
  
}

}
.dark_bg{
    background: #8072D5;
}
.sub_title{
    font-size: 24px;
    font-weight: bold;
}
.title_line{
    display: inline-block;
    height: 2px;
    width: 40px;
    background-color: #8080dc;
    border-radius: 100px;
    position: relative;
    top: -3px;
    margin: 0 5px;
}
.article_head{
    font-size: 64px;
    font-weight: 900;
}
.article_head1{
    font-weight: 900;
}
.main_heading{
    font-size:88px;
    font-weight: bold;
}
.fs_20{
    font-size: 20px;
}
.search_box{
    border-radius: 30px;
    background: #8072D5;
    padding: 7px;
}
.search_input{
    background: transparent !important;
    border: none;
    box-shadow: none !important;
    color: #fff !important;
}
.search_input::placeholder {
    color: #fff;
  }
  
 .search_input::-ms-input-placeholder { 
    color: #fff;
  }
  .search_btn{
    background: #D9D6F3 !important;
    color: #8072D5 !important;
    border-radius: 30px !important;
    padding: 5px 25px;
    font-size: 16px;
    font-weight: 500;
  }
  .default_btn{
    background: #8072D5 !important;
    color: #fff !important;
    border-radius: 30px !important;
    padding: 5px 25px;
  }
  .contact_heading{
    font-size: 76px;
    font-weight: 600;
  }
  .search_input:focus {
    border-color: transparent !important;
    outline: 0;
    box-shadow: none !important;
}
.industry_card{
    background: #958ED4;
    border: none;
    border-radius: 20px;
}
.article_card{
    border: none;
}
.article_icn_bg1{
    background: #DDCCED !important;
    /* height: 212px; */
}
.article_icn_bg2{
    background: #EDDDCC !important;
}
.article_icn_bg3{
    background: #CCEDED !important;
}
.default_text{
    color: #8072D5 !important;
}
.home_btn{
    /* background: #d9d6f3 !important;
    color: #8072D5 !important; */
    margin: auto;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}
.home_btn i{
    padding: 12px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}
.icn_bg1{
    background: #8072d5;
    color: #DDCCED;
}
.icn_bg2{
  background: #ffae57;
  color: #EDDDCC;
}
.icn_bg3{
  background: #29d4e4;
  color: #CCEDED;
}
.play_btn{
    color: #8072D5 !important;
    font-weight: 600;
    z-index: 1;
    /* position:absolute; */
}
.play_btn i{
    font-size: 22px;
    position: relative;
    top: 3px;
}
.card_head{
    font-size: 46px;
    font-weight: 900;
}
.bold{
    font-weight: 900;
}
.card_icn{
  width: 345px;
  height: 212px;
  border-radius: 7px;
}
.radius_20{
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .main_heading {
        font-size: 40px;
    }
    .card_icn {
      width: 298px;
      height: 212px;
      border-radius: 7px;
    }
    .card_head {
        font-size: 26px;
    }
    .fs_20 {
        font-size: unset;
    }
    .article_head {
        font-size: 36px;
    }
    .direction_flex{
        /* flex-direction: column; */
        gap: 5px;
    }
    .contact_heading {
        font-size: 30px;
    }
    .contact_bg{
        background: #D9D6F3;
    }
    .about_head {
    font-size: 42px;
    }
    /* .text_center{
        text-align: center !important;
    } */
    .industry_btn {
        border-radius: 5px;
        padding: 5px 10px;
    }
    .banner_img{
        width: 90% !important;
      }
      .footer_banner {
        font-size: unset;
    }
    .contact_button {
      width: 80%;
    }
    .contact_button1 {
      width: 80%;
    }
    .contact_button2 {
      width: 80%;
    }
  }

  .bg1{
    background: #CCDDED;
  }
  .bg2{
    background: #CCEDED;
  }
  .bg3{
    background: #EDCCCC;
  }
  .bg4{
    background: #DDEDCC;
  }
  .bg5{
    background: #D1CCED;
  }
  .our_mission{
    background: url(../../public/assets/image/recources_bg.png) no-repeat;
    background-size: cover;
  }
  .icon_box{
    height: 120px;
    width: 90px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .icon_box_img{
    padding: 13px;
  }
  .card_icn2{
    height: 85px;
  }
  .banner_img{
    width: 57%;
    position: relative;
  }

  /* home slider image start*/
  .slide_img1{
    width: 10%;
    height: 100%;
  }
  .slide_img{
    width: 70%;
    margin: auto;
    display: block;
  }
  .slide_box{
    display: inline-flex;
    align-items: center;
  }
  /* home slider image close*/

  .privacy_and_policy_area ul{
    list-style-type: disc;
  }
  .privacy_and_policy_area ul li{
    margin-bottom: 10px;
  }
  .bold1{
    font-weight: 600;
  }
  .privacy_head{
margin-bottom: 10px;
  }
  .privacy_and_policy_area p{
    margin-bottom: 20px !important;
  }

  div:where(.swal2-container) div:where(.swal2-popup){
    width: auto;
    padding: 0;
    background: transparent;
  }
  div:where(.swal2-container) .swal2-html-container{
    margin: 0;
  }
  .video_popup{
    width: 560px;
    max-width: 100%;
    height: 315px;
  }

  .footer_list li {
    padding-bottom:30px;
}
  @media screen and (max-width:600px){
.footer_list li {
    display: inline;
    padding: 10px;
}
.radius1{
  border-radius: 0 0 20px 20px !important;
}
.radius2{
  border-radius: 20px 20px 0 0 !important;
}
.radius3{
  border-radius: 0 !important;
}
.rev_radius1{
  border-radius: 20px 20px 0 0 !important;
}
.rev_radius2{
  border-radius: 0 0 20px 20px !important;
}
.rev_radius3{
  border-radius: 20px !important;
}
}
.radius1{
  border-radius: 0 0 20px;
}
.radius2{
  border-radius: 20px 20px 0 0;
}
.radius3{
  border-radius: 20px 0 0 20px;
}

.rev_radius1{
  border-radius: 0 0 0 20px;
}
.rev_radius2{
  border-radius: 0px 20px 20px 0px;
}
.rev_radius3{
  border-radius: 20px 20px 0 0;
}