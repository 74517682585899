.nav-link {
  color: #131316 !important;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding: 15px 0;
  margin-left: 37px;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-brand {
  color: #231d1d;
  font-weight: bold;
  font-size: 25px;
}
.nav-link:focus, .nav-link:hover {
  color: rgb(35 30 30);
}
.colornav {
  border-radius: none;
  border: 1px solid #000000;
  padding: 13px;
  width: 152px;
  font-family: sans-serif;

}
.usecasebtn {
  border-radius: 30px;
  padding: 13px;
  font-family: 'Poppins', sans-serif;
  width: 138px;
  height: 48px;
  border: none;
    background: none;
    color:#8072D5;
   font-size:16px;
   font-weight: 600;
  
}
.usecasebtn1 {
  border-radius: 99px !important;
  border: 1px solid #D9D6F3;
  padding: 13px;
  width: 138px;
  height: 48px;
  font-family: sans-serif;
  background-color: #D9D6F3;
  color: #8072D5;
  font-weight: 600 !important;
  font-family: 'Poppins', sans-serif;
}
.usecasebtn12 {
    border-radius: 99px !important;
    /* border: 1px solid #D9D6F3; */
    padding: 13px;
    width: 154px;
    height: 58px;
    font-family: sans-serif;
    background-color: #ffffff;
    color: #8072D5;
    font-weight: 600 !important;
    border: none;
    background-color: none;
    font-family: 'Poppins', sans-serif;
}
.heading_about{
  font-size: 56px;
  font-family: 'Poppins', sans-serif;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: rgb(255 255 255);
  background-color: #1d1a1a;
  /* border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color); */
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
.aboutimg{
  width: 372PX;
  z-index: 1;
    position: absolute;
}
@media only screen and (max-width: 600px) {
  .aboutimg{
    width: 378px;
  }
}
.usecasebtn2{
  border-radius: 14px;
  border: 1px solid#6f735a;
  padding: 13px;
  width: 432px;
  font-family: sans-serif;
  background-color: #e0fe51;
  color:#030203;
  font-weight: bold;

}
.your-flex-container {
  display: flex;
  justify-content: space-between;
}
.usecasebtn3 {
  border-radius: 14px;
  border: 1px solid#6f735a;
  padding: 13px;
  width: 432px;
  font-family: sans-serif;
  background-color: #965ef5;
  color: #ffffff;
  font-weight: bold;
}
.usecasebtn4 {
  border-radius: 14px;
  border: 1px solid#6f735a;
  padding: 13px;
  width: 432px;
  font-family: sans-serif;
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
}
.section-heading .title {
  font-size: 40px;
  line-height: 70px;
  font-weight: bold;
  color:#fcfdfe;
  margin-bottom: 10px;
}
.Advantagebg{
  background-color:#965ef5;

}
h1{
  font-family: "Ubuntu", sans-serif;
}
.section-heading {
  text-align: center;
}
.Advantagesheading{
  font-size: 42px;
  color: white;
  font-family: sans-serif;
  font-weight: bold;
}
.cardheading{

    font-size: 35px;
    color: #000000;
    font-family: sans-serif;
    font-weight: bold;
}
.cardradius{
  border-radius: 25px;
  border: 1px solid black;
}
.indenttext{
  font-size: 53px;
  color: #000000;
  font-family: sans-serif;
  font-weight: bold;
}
.numbertext{
  font-size: 80px;
  color: #ffffff;
  font-family: auto;
}
.textpargh{
  color: #b0b0b0;
}
.bg-dark{
  background-color: #000000 !important;
}
.btntext1{
  margin-top: 138px;
    margin-left: 88px;
}
.plusnumber{
  font-weight: bold;
}

.heading_second{
  font-size: 60px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #000000;
}
.btn_book{
  border-radius: 5px !important;
  border: 1px solid  #070628;
  /* padding: 13px; */
  width: 126px;
  height: 42px;
  font-family: sans-serif;
  background-color: #070628;
}

.imgtree{
  width:100%;
}
.paratext{
  color: #383942;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.paratext1{
    color: #383942;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.collabrators {
  background: #d9d6f3;
  padding: 0px 44px;
  padding: 30px 100px 30px;
}
.collbration_div {
  margin: 60px auto;
    margin-left: 90px;
}
.s_6 {
  font-size: 28px;
  color: #212529 !important;
  font-weight: 600;
  border: none;
}
.collabrators button {
  background: #8072d5;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 5px;
  align-content: baseline;
}
.footer_parademo{
  color: #000;

}
.btn {
  display: inline-block;
  font-weight: 400;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  /* border: 1px solid transparent; */
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.footer1 {
  padding-top: 40px;
  padding-right: 0;
  padding-bottom: 20px;
  padding-left: 0;
}
.title-widget {
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  position: relative;
  text-transform: capitalize;
  font-family: 'Noto Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 20px;
  color: #4c5863;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.text-reset {
  text-decoration: none!important;
  line-height: 24px !important;
  color: #000 !important;
  font-size: 14px;
}
.contact_button {
     background: #ddcced;
  color: #0d0101;
  border-radius: 8px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 8px auto;
}
.contact_button1 {
  background: #edddcc;
  color: #0d0101;
  border-radius: 8px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 8px auto;
}
.contact_button2 {
  background: #CCEDED;
  color: #0d0101;
  border-radius: 8px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 8px auto;
}
.social_icon {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  gap: 20px;
}
a {
  outline: 0!important;
  text-decoration: none!important;
  color: #000!important;
}
.region {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  gap: 20px;
  color: gray;
}
.social_icon i {
  color: #8072d5;
  font-size: 23px;
}
.fa-brands, .fab {
  font-weight: 400;
}
.fa-classic, .fa-regular, .fa-solid, .far, .fas {
  font-family: "Font Awesome 6 Free";
}
.fa, .fa-brands, .fa-classic, .fa-regular, .fa-sharp, .fa-solid, .fab, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  /* text-rendering: auto; */
}
.dta_xyz {
  color: #000;
  margin-bottom: 15px !important;
}
.copyryt_col {
  display: flex;
  justify-content: end;
  gap: 10px;
  color: gray;
}
.footer_cpy_ryt {
  font-size: 13px;
  margin-top: 5px;
}
.footer_banner i {
  background: #8072d5;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  margin-left: 10px;
}
.footer_banner {
  color: #fff;
  font-size: 25px;
}
.contact_area {
  margin: 100px auto;
}
.contact_titt {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #8072D5;
  font-weight: 600 !important;
}
.contact_area h1 {
  margin: 16px auto;
  font-size: 48px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
  letter-spacing: -.02em;
}
.contact_area p {
  font-family: 'Mulish', sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 150% !important;
  color: #6c7689 !important;
  margin-bottom: unset !important;
}
.form-d {
  margin-bottom: 20px;
}
label {
  display: inline-block;
  margin-bottom: 5px;
}
.hs-form-required {
  color: #D1292D;
  font-family: Mulish;
}

dt, label {
  font-weight: 700;
}
.form-d input {
  padding: 23px !important;
  border: 1px solid #dfdfdf !important;
}
.form-control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.hs-form-required {
  color: #D1292D;
  font-family: Mulish;
}
.multi-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  list-style-type: none;
  padding: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-left: 25px;
  padding-bottom: 15px;
  border: 1px solid #DFE2E7;
  border-radius: 5px;
}
.multi-container span, .multi-container-radio span {
  margin-left: 10px;
  font-size: 14px;
  font-family: Mulish !important;
  letter-spacing: 0px;
  font-weight: 700;
}
.multi-container-radio {
  list-style-type: none;
  padding: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-left: 25px;
  padding-bottom: 15px;
  border: 1px solid #DFE2E7;
  border-radius: 5px;
  display: flex;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  grid-column-gap: 30px;
}
.hs-button {
  padding: 15px;
  background-color: #D9D6F3;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: 200ms;
  color: #8072D5;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif;
}
.faqtittle {
  text-align: center;
  margin: 75px auto;
}
.text-style-cap {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #8072D5;
  font-weight: 600 !important;
}
.faqtittle h2 {
  color: #000;
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 110%;
}
#contactmain {
  background: #f0f2f4;
}
#contactmain #faq .card_contact {
  border: 0;
  border-bottom: 1px solid #e8e3e3;
}
.accordion-button{
  padding:30px;

    text-align: left;
    font-size: 18px;
}
.bgcolor{
  background-color:#f0f2f4;
}
.privacy_and_heading {
  border-bottom: 1px solid #d7d7d7;
}
.privacy_and_heading h1 {
  font-weight: bold;
  font-size: 36px;
  margin: 20px 0;
}
.privacy_and_policy_area {
  margin-top: 50px;
  margin-bottom: 40px;
}
.privacy_and_policy_area h5 {
  font-size: 23px;
  font-weight: bold;
  margin: 30px 0;
}
.content p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 0px !important;
}
.privacy_and_policy_area p {
  font-size: 16px;
  color: #373737;
}
.presssec1 {
  margin: 100px auto;
}
.p_sec1 {
  text-align: center;
}
.sec_img {
  font-family: 'Noto Sans', sans-serif;
  font-size: 40px;
  margin: auto;
  font-weight: bolder;
}
.sec1_img {
  width: 80px;
  margin: auto;
}
.p_sec1 h2 {
  font-size: 45px;
  font-weight: bold;
  margin: 25px auto;
  font-family: 'Noto Sans', sans-serif;
}
.p_sec1 p {
  max-width: 628px;
  margin: 35px auto;
  font-size: 17px;
  color: gray;
  font-family: 'Lato', sans-serif;
}
.presssec2 {
  margin: 40px auto;
}
.press_Sec2_main {
  align-content: center;
  align-items: center;
  background-color: #fff;
  display: flex;
  position: relative;
  margin: auto;
  justify-content: center;
}
.press_json {
  width: 400px;
}
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.press_Sec2_main h2 {
  font-size: 22px;
  font-weight: bold;
}
.press_Sec2_main p {
  font-size: 16px;
  font-family: 'Mulish', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 150% !important;
  color: #6c7689 !important;
  max-width: 500px;
}
.pressheading4 {
  font-size: 43px;
  font-weight: bold;
}
.bg1-div {
  height: 5.125rem;
  background: #8072d5!important;
}
.bg2-div {
  height:  5.125rem;
  background: #d9d6f3;
}
.txt4 {
  text-align: center;
  margin: 27px auto;
  color: #fff;
  font-size: 23px;
  text-transform: uppercase;
  letter-spacing: 10px;
}
.career {
  margin: 85px auto;
}
.career h1 {
  font-size: 57px;
  font-weight: bolder;
  font-family: 'Noto Sans', sans-serif;
}
.career p {
  color: #605f5f !important;
  font-size: 20px !important;
  line-height: 30px;
  font-family: 'Karla', sans-serif;
  margin: 20px auto;
}
.career_button {
  background: rgb(217 214 243);
  text-align: center;
  padding: 12px 35px;
  color: #8072d5;
  border-radius: 12px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  margin: 15px auto;
}
.career_section{
  background:#f3f6f8;
}

.section_2_tittle p {
  margin: 20px auto;
  font-size: 19px;
  color: gray;
  max-width: 800px;
}
.section_2_tittle{
  max-width: 800px;
  text-align: center;
  margin:50px auto;
}
.section_2_tittle h2{
  font-size: 40px;
  font-weight: bold;
}

.section_2_tittle p{
  margin: 20px auto;
  font-size: 19px;
  color: gray;
  max-width:800px;
}
.career_card{
  min-height:250px;
}
.section_card_m h3 {
  font-size: 25px !important;
font-weight: 900;
}
.section_card_m p {
  color: #605f5f;
  font-size: 19px;
  line-height: 30px;
  max-width: 900px;
  font-family: 'Karla', sans-serif;
  margin: 10px auto;
}
.team_sec2 {
  margin: 85px auto;
}
.gallery_div1 {
  height: var(--framer-aspect-ratio-supported, 385px);
  overflow: visible;
  position: relative;
  margin-bottom: 30px;
}
.gallery_img1 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
.gallery_div2 {
  aspect-ratio: .97 / 1;
  flex: none;
  height: 100%;
  overflow: visible;
  position: relative;
  width: var(--framer-aspect-ratio-supported, 373px);
  margin-bottom: 30px;
}
.gallery_img2 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
.benifit {
  margin: 85px auto;
}
.benifit h2 {
  font-size: 40px !important;
  font-family: sans-serif !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 65px;
}
.career_card2 {
  min-height: 300px;
}
.career_card2 img {
  width: 60px !important;
  margin: 18px auto !important;
}
.sec3_card_m h3 {
  font-size: 22px !important;
  font-family: sans-serif !important;
  font-weight: bolder;
}
.sec3_card_m p {
  color: #605f5f;
  font-size: 19px;
  line-height: 30px;
  max-width: 900px;
  font-family: 'Karla', sans-serif;
  margin: 10px auto;
}
.career_section5 {
  margin: 85px auto;
  background: #cceded;
  padding: 20px;
}
.sec5_tittle {
  margin: 60px auto;
}
.career_section5 h2 {
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
}

.section5_button {
  background: rgb(217 214 243);
  text-align: center;
  padding: 10px 50px;
  color: #8072d5;
  border-radius: 12px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  margin: 40px auto;
}
.section5_img {
  margin: auto;
  width: 95%;
  text-align: center;
  align-items: center;
}
.secimg1 {
  max-width: 100%;
  vertical-align: middle;
}
.career2 {
  margin: 30px 0 60px;
}
.career2 h2 {
  font-size: 20px;
  color: #555;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.career2 ul {
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
}
.heading-underline {
  font-size: 17px!important;
}
.career2 ul li {
  padding: 2px 20px 12px 20px;
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.career2 p {
  margin-bottom: unset;
  font-size: 17px;
  font-family: Arial;
  color: #373737;
}
.press_jsonnew{
  width: 603px;
  margin-left: -18px;

}
.team_sec1 {
  margin: 85px auto;
}
.team_sec1 h1 {
  font-size: 57px;
  font-weight: bolder;
  font-family: 'Noto Sans', sans-serif;
}
.team_sec1 p {
  color: #605f5f !important;
  font-size: 20px !important;
  line-height: 30px;
  font-family: 'Karla', sans-serif;
  margin: 20px auto;
}
.team_sec2 {
  margin: 85px auto;
}
.gallery_div1 {
  height: 385px;
  overflow: visible;
  position: relative;
  margin-bottom: 30px;
}
.gallery_img1 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
.gallery_div2 {
  aspect-ratio: .97 / 1;
  flex: none;
  height: 93%;
  overflow: visible;
  position: relative;
  width: 373px;
  margin-bottom: 30px;
}
.gallery_img2 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
.gallery_div3 {
  aspect-ratio: 1.3506666666666667 / 1;
  flex: 1 0 0px;
  height: 413px;
  overflow: visible;
  position: relative;
}
.gallery_img3 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
.gallery_div4 {
  aspect-ratio: 1.3706666666666667 / 1;
  flex: 1 0 0px;
  height: 385px;
  overflow: visible;
  position: relative;
}
.gallery_img4 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
.founder_sec {
  margin: 85px auto;
  background: #f3f6f8;
  /* padding: 40px; */
  border-radius: 25px;
}
.founder_img {
  aspect-ratio: 1 / 1;
  border-radius: 24px;
  flex: none;
  height:  300px;
  overflow: visible;
  position: relative;
  width: 300px;
}
.foun_im {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
.founder_sec h3 {
  font-size: 30px;
  font-weight: bold;
  font-family: 'Noto Sans', sans-serif;
  margin: 0 0 5px 0;
}
.founder_sec p {
  font-size: 18px !important;
  max-width: 800px;
  color: gray;
}
.founder_sec p {
  font-size: 18px !important;
  max-width: 800px;
  color: gray;
}
.team_mem_link {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin: 5px auto;
}
.cardbg1{
  background: #f3f6f8;
  border:none !important;
}
.team_mem_link i {
  color: #9c9c9d !important;

}
.additional-icon {
  color: #42444a;
  font-size: 22px;
}
address, blockquote, dl, ol, p, pre, table, ul {
  margin-bottom: 1.75em;
}
.team-sec3 {
  align-content: center;
  align-items: center;
  background-color: var(--token-f71b4ccf-f421-4dd2-8423-cee925482167, #f3f6f8);
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.we-re_tittle {
  padding: 100px 0;
  text-align: center;
}
.team-sec3 h3 {
  font-size: 40px;
  font-weight: bolder;
  font-family: 'Noto Sans', sans-serif;
  margin: 0 0 45px 0;
}
.team-sec3 p {
  font-size: 20px !important;
  max-width: 800px;
  color: gray;
  margin: auto;
}
.team_button {
  background: rgb(0 0 0);
  text-align: center;
  padding: 15px 50px;
  color: #fff !important;
  border-radius: 12px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  margin: 15px auto;
}
.the_team h2 {
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
  font-family: 'Noto Sans', sans-serif;
  margin: 20px auto;
}
.the_team{
  margin:85px auto;
}
.the_team ul li{
padding: 10px 20px;
margin: 5px;
cursor:pointer;
border-bottom: 2px solid #FFF;
transition: 0.5s linear;
color: #747d8c;

}
.team_pill_tab{
  text-align:center !important;
}
.nav_item_li{
float: none !important;
display: inline-block !important;
text-align: center;
}
.team-nav-link{
   /*background:none !important;*/
color: gray; 
font-size: 12px;
font-weight: bold;
padding: 8px 12px;
border-radius: 8px !important;
}
.nav_item_li a:hover{
  background:unset !important;
  color:unset !important;
}
.the_team li.active{
  background-color:rgb(214, 254, 241) !important;
  color:#007d7c !important;
  border-radius: 8px !important;
}

.the_team li:hover{
background-color: rgb(245, 245, 245) !important;
color: #727575 !important;
border-radius: 8px !important;
}
.the_team h2{
text-align: center;
font-size: 40px;
font-weight: bolder;
 font-family: 'Noto Sans', sans-serif;
 margin:20px auto;
}
.about_team_mem{
flex: none;
height: auto;
position: relative;
width: 150px;
margin:auto
;
}
.tittle_abt_team{
  text-align:center;
}
.team_mem_name{
margin: 12px auto;
font-size: 16px;
font-weight: bolder;
display: flex;
gap: 5px;
justify-content:center;
}
.team_mem_desigination{
  margin-bottom:unset !important;
}
.team_mem_link{
display: flex;
justify-content: center;
gap: 5px;
margin: 5px auto;
}
.team_mem_link i{
color:#9c9c9d !important;
} 
.abt_team_section{
display: flex;
flex-flow: row wrap;
align-items: center;
justify-content: center;
gap: 16px;

}
.about_team_mem {
  flex: none;
  height: auto;
  position: relative;
  width: 150px;
  margin: auto;
}
.tittle_abt_team {
  text-align: center;
}
.team_mem_name {
  margin: 12px auto;
  font-size: 16px;
  font-weight: bolder;
  display: flex;
  gap: 5px;
  justify-content: center;
}
.team_mem_designation {
  margin-bottom: unset !important;
  text-align: center !important;
}
.team_mem_link {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin: 5px auto;
}
.team_mem_link i {
  color: #9c9c9d !important;
}
.teamimg{
  width:150px;
}
.main-container {
  overflow: hidden;
}

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 12px 120px 12px 120px;
  background: #121316;
}
.text-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 1200px;
  padding: 0 8px 0 8px;
}
.announcement-bar {
  flex-shrink: 0;
  position: relative;
  width: 542px;
  font-family: Poppins, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  letter-spacing: -0.28px;
  z-index: 1;
}
.announcement-bar-text {
  position: relative;
  color: #f4f4f6;
  font-family: Poppins, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  letter-spacing: -0.28px;
}
.globe_icn{
  color: #fff;
  background: #26262d;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 13px;
}
.globe_drp{
  background: transparent !important;
  border: none !important;
  color: #9186d9;
  font-weight: 600;
}
.top_head_p {
  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px;
  color: #fff;
  margin-bottom: 0;
}
.important-text {
  position: relative;
  color: #9186d9;
  font-family: Poppins, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  letter-spacing: -0.28px;
}
.language {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 63px;
  z-index: 2;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  padding: 4px 4px 4px 4px;
  background: #26262d;
  z-index: 3;
  border-radius: 99px;
}
.language-1 {
  flex-shrink: 0;
  position: relative;
  width: 16px;
  height: 16px;
  background: url(components/image/icon.png)
  no-repeat center;
  background-size: cover;
  z-index: 4;
  overflow: hidden;
}
.caption {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 39px !important;
  padding: 4px 4px 4px 4px;
  z-index: 5;
  border-radius: 99px;
}
.en {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #8072d5;
  font-family: Poppins, var(--default-font-family);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.24px;
  z-index: 6;
}
.pic-2 {
  flex-shrink: 0;
  position: relative;
  width: 16px;
  height: 16px;
  background: url(components/image/expand_more.png)
    no-repeat center;
  background-size: cover;
  z-index: 7;
  overflow: hidden;
}
.aboutbg{
  background: url(components/image/aboutbg.png);
  background-repeat: no-repeat;
  background-size: auto;
  
}
.main-container1 {
  overflow: hidden;
}

.main-container1,
.main-container1 * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container1 {
  position: relative;
  width: 380px;
  height: 61px;
  margin: 0px 5px 0px 0px;
}
.rectangle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #d9d6f3;
  border-radius: 10px;
}
.arrow-right-circle {
  position: absolute;
  width: 8.42%;
  height: 52.46%;
  top: 24.59%;
  left: 88.16%;
  background: url(components/image/arrow.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 2;
  overflow: hidden;
}
.workflow-1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 39.34%;
  top: 31.15%;
  left: 5.26%;
  color: #8072d5;
  font-family: Poppins, var(--default-font-family);
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.44px;
  z-index: 1;
}
.heading_color_span{
  color:#8072D5;
}
.heading_text_color{
color:#383942;
font-family: 'Poppins', sans-serif;
}

/* .banner_bg{
  background: url(components/image/Ellipse55.png);
} */
.main-containe2 {
  overflow: hidden;
}

.main-container2,
.main-container2 * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 25px;
  position: relative;

  margin: 0 auto;
}
.title-caption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
}
.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 119px;
  height: 16px;
  padding: 0;
  z-index: 1;
  border-radius: 99px;
}
.kyc-verification-software {
  flex-shrink: 0;
  position: relative;
  width: 739px;
  font-family: Poppins, var(--default-font-family);
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  letter-spacing: -2.4px;
  z-index: 2;
}
.individual-and-cooperate {
  position: relative;
  color: #121316;
  font-family: Poppins, var(--default-font-family);
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  letter-spacing: -2.4px;
}
.kyc-verification {
  position: relative;
  color: #8072d4;
  font-family: Poppins, var(--default-font-family);
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  letter-spacing: -2.4px;
}
.software {
  position: relative;
  color: #121316;
  font-family: Poppins, var(--default-font-family);
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  letter-spacing: -2.4px;
}
.versatile-solution {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 486px;
  height: 72px;
  color: #383942;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.32px;
  z-index: 3;
}
.Database{
color: #8072d5;
font-family: 'Poppins', sans-serif;
}

.home_heading{
  font-size: 48px;
    font-weight: bold;
}
@media only screen and (max-width: 480px){
.home_heading {
    font-size: 30px;
}
.btntext1 {
  margin-top: 50px;
  margin-left: 0;
}
.multi-container span, .multi-container-radio span {
  font-size: 9px;
}
}
@media only screen and (min-width: 600px){
  .home_banner {
      position: absolute;
  }
  }
  @media only screen and (min-width: 600px){
    .about_head {
      font-size: 35px;
  }
    }
/* gradient background start */
@media only screen and (min-width: 600px) {
.absolute {
  position: absolute;
  top: 110px !important;
  inset: 0;
  justify-content: center;
  display: inline-flex;
  flex-direction: row;
  margin-left: 157px;
}
.justify-center{
  justify-content: center;
}
.bg-shape1{
  width: 300px;
  height: 300px;
  border-radius: 9999px;
  position: relative;
  animation: one 10s infinite;
}
.bg-shape2{
  width: 200px;
  height: 200px;
  border-radius: 9999px;
  position: relative;
  animation: two 10s infinite;
}
@keyframes one{
  0%{left: 0px; top: 0px;}
  25%{left: -100px; top: 70px;}
  50%{left: 20px; top: 150px;}
  75%{left: 50px; top: 100px;}
  100%{left: 0px; top: 0px;}
}

@keyframes two{
  0%{left: 0px; top: 0px;}
  25%{left: 50px; top: 10px;}
  50%{left: 100px; top: 50px;}
  75%{left: 50px; top: 100px;}
  100%{left: 0px; top: 0px;}
}

.opacity-50{
  opacity: 0.5;
}

.bg-blur{
  filter: blur(90px);
}
.bg-purple{
  background: #A99EED;
}
.bg-red{
  background: #EBC8DC;
}
.bg-teal{
  background: rgb(255, 255, 255);
}
}
/* gradient background close */
.main-about {
  overflow: hidden;
}

.main-about,
.main-about * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 32px;
  position: relative;
  width: 670px;
  margin: 0 auto;
}
.text-1 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  width: 233px;
}
.line {
  flex-shrink: 0;
  position: relative;
  width: 113px;
  height: 1px;
  background: url(components/image/Line\ 1.png)
    no-repeat center;
  background-size: cover;
  z-index: 1;
}
.about-us {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 26px;
  color: #0b0b0b;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.transforming-digital-visions-into-reality {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 670px;
  min-width: 0;
  height: 140px;
  color: #0b0b0b;
  font-family: 'Poppins', sans-serif;
  font-size: 64px;
  font-weight: 600;
  line-height: 70.4px;
  text-align: left;
  z-index: 3;
}
.digital-marketing-agency {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 670px;
  min-width: 0;
  height: 84px;
  color: #5f5f5f;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  z-index: 4;
}
.main-card {
  position: relative;
  width: 1170px;
  height: 645px;
  margin-top: -362px;
  background: url(components/image/bgcard.png)
    no-repeat center;
  background-size: cover;
}
.main-card{
  overflow: hidden;
}
.main-card,
.main-card * {
  box-sizing: border-box;
}

.custom_card{
  background: #d9d6f3;
  border: none;
}
.container_bg{
  background: #d9d6f3;
}
.vision_head{
  font-size: 75px;
  font-weight: 700;
}
.box_border{
  border-radius: none;

}
.heading_cont{
  font-size: 24px;
  font-weight: 500;
  color: #0C0C0C;

}
.heading_cont1{
  
  font-size: 72px;
  font-weight: 600;
  line-height: 79.2px;
}

.card_border{
  border: none;
}
.card_bg{
  background: #D9D6F3;
}
.card_bg_mob{
    background: #D9D6F3;
}
.about_head{
  font-size: 65px;
  font-weight: 600;
}
.about_head_product{
  font-size: 50px;
  font-weight: 600;
}
.home_bt{
  background: #D9D6F3 !important;
    color: #8072D5 !important;
    border-radius: 30px !important;
    padding: 5px 25px;
    font-weight: 600;
    transition: 0.25s;
}
.about_btn1-check:checked+.about_btn1, .about_btn1.active, .about_btn1.show, .about_btn1:first-child:active, :not(.about_btn1-check)+.about_btn1:active:active{
  background: #D9D6F3 !important;
    color: #8072D5 !important;
    border-radius: 30px !important;
    padding: 5px 25px;
    font-weight: 600;
    transition: 0.25s;
}
.about_btn1{
  background: #fff;
    color: #7B7B7B;
    border-color: #7B7B7B;
    padding: 5px 25px;
    border-radius: 30px !important;
    transition: 0.25s;
}
.about_btn1:hover{
    color: #000;
    border-color: #7B7B7B;
    transition: 0.25s;
}
.service_card1{
  border: none;
  background-color: #ddedcc;
  border-radius: 30px;
  height: 336px;
}
.service_card2{
  border: none;
  background-color:#CCDDED;
  border-radius: 30px;
  height: 336px;
}
.service_card3{
  border: none;
  background-color:#CCEDED;
  border-radius: 30px;
  height: 336px;
}

.server_heading{
  font-size: 23px;
  font-weight: 600;
}
.server_para{
  font-size: 14px;
  color:#0C0C0C;

}

.search_btn123 {
  background: #D9D6F3 !important;
  color: #8072D5;
  border-radius: 30px !important;
  padding: 5px 25px;
  font-size: 16px;
  font-weight: 600;
}

.circle_btn{
  height: 45px;
  width: 45px;
  background: #d9d6f3;
  color: #8072d5;
  border-radius: 50%;
  border: 1px solid #8072d5;
}

@media only screen and (max-width: 600px) {
  .collbration_div {
    margin: 60px auto;
    text-align: center;
    
}
.card_bg_mob{
  background: transparent;
}
.card_bg{
 border-radius: 0;
}
.about_head_product {
  font-size: 30px;
  font-weight: 600;
}
}
@media only screen and (max-width: 600px) {
  .collabrators {
    background: #000000;
    padding: 0px 44px;
  
}
}
@media screen and (max-width: 474px){
.nft_market_09xuv {
    margin: auto;
    text-align: center;
}
}

.nft_market_09xuv li {
  line-height: 32px !important;
  color: #000;
  font-size: 14px;
}
@media screen and (max-width: 474px){
.region, .copyryt_col, .social_icon {
    justify-content: center !important;
}
}
.kycverfication{

color: #8072d4;
}
.cooperatekyc{
  font-family: 'Poppins', sans-serif;
  font-size: 53px;
    font-weight: bold;
    letter-spacing: -2.4px;
    line-height: 72px;
    position: relative;
    text-align: left;
    color: #121316;
}
.kycpara_heading{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #383942;

}
.reliale_heading{
  
color:#212529;
font-weight: 600;
font-size: 16px;
}
.reliale_para{
  text-align: left;
  margin-left: 10px;
  font-size: 11px;

}
@media screen and (min-width: 474px) {
  .mobliehidebtn {
   display: none;
  }
}
@media only screen and (max-width: 600px) {
  .kycpara_heading {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #383942;
}
}
.logoimg{
height:30px;

}
.cardbg5{
border: none;
background: #DDCCED !important;
    border-radius: 15px;
    height: 321px;
}
.cardbg6{
  border: none;
  background: #EDDDCC !important;
      border-radius: 15px;
      height: 312px;
  }
  .cardbg7{
    border: none;
    background: #CCEDED !important;
        border-radius: 15px;
        height: 312px;
    }
    .cardbg8{
      border: none;
      background-color: #ddedcc;
          border-radius: 15px;
          height: 312px;
      }
      .product_heading{
        color: #212529;
    font-weight: 600;
    font-size: 16px;
      }
      .product_para{
        font-size: 13px;
    text-align: left;

      }
      .feature_heading{
      text-align: center;
      font-size: 18px;
    font-weight: 600;
      }
    
    .safety-card {
      padding: 10px 30px;
  }
  .safe_heading{
    font-weight: 600;

  }
  .feature_para{
  font-size: 15px;
    text-align: left;
    margin-left: 18px;
  }
  .main_headpoint{
    font-size: 17px;
    line-height: normal;
  }
  .payment-section {
    background: url(components/image/face1.svg) left no-repeat;
    background-position: 0% 100%;
    height: 800px;
    background-size: contain;
}
.payment-section1 {
  background: url(components/image/bus.svg) left no-repeat;
  background-position: 0% 100%;
  height: 800px;
  background-size: contain;
}
@media (min-width: 768px){
.align-items-md-center {
    align-items: center!important;
  
}

}
@media (min-width: 576px){
.text-sm-start {
    text-align: left!important;
}
}
.dowload_heading{
  font-size: 35px;
  font-weight: 600;

}

@media only screen and (max-width: 600px) {
  .dowload_heading{
    font-size: 25px;
    font-weight: 600;
  
  }
}
@media (min-width: 576px){
.my-sm-5 {
    margin-top: 3rem!important;
    margin-bottom: 3rem!important;
}
}



.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  /* background-color: #0d6efd; */
  background: #d9d6f3;
  color: #8072d5 !important;
  border-radius: 12px;
  border: 1px solid #8072d5;
}
.nav-pills .nav-link {
  background: #d9d6f3;
  color: #8072d5 !important;
  border-radius: 12px;
  border: 1px solid #d9d6f3;
}
.btnchange{
  position: absolute;
    bottom: 15%;
    left: 63%;
    transform: translate(-25px);
}

@media only screen and (max-width: 600px) {
  .btnchange{
    position: absolute;
      bottom: 15%;
      left: 63%;
      transform: translate(-25px);
  }
  
}
.appstorage_heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 60px;
}
.googleplay_img{
  width:28px;

}
@media only screen and (max-width: 480px) {
  .appstorage_heading {
    font-size: 15px;
  font-weight: bold;
  margin-bottom:30px;
  }
  .heightdow {
    margin-top: 35px;
}
.btnchange {
  position: absolute;
  bottom: 44%;
  left: 35%;
  transform: translate(-25px);
}
}
.heightdow{
  margin-top: 147px;
}
@media only screen and (max-width: 480px) {
  .heightdow {
    margin-top: 35px;
}
}
@media only screen and (max-width:1900px) {
  .collbration_div {
    margin: 60px auto;
    margin-left: 192px;
  }
}
@media only screen and (max-width:1708px) {
  .collbration_div {
    margin: 60px auto;
    margin-left: 280px;
  }
}

@media only screen and (max-width: 500px){
.collbration_div {
    margin: 60px auto;

}
}
.fs_161{
  font-size: 17px;

}
.fw-600{
  font-weight: 600;

}

.section-about-people {
  padding-bottom: 56px;
  padding-top: 112px;
}
.container-medium {
  margin-left: auto;
  margin-right: auto;
  max-width: 1205px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
}
.careers-culture-flex {
  grid-column-gap: 40px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.careers-culture-flex-text {
  flex: none;
  max-width: 587px;
  width: 100%;
}
.text_left {
  text-align: left;
}
.text-style-caps {
  text-transform: uppercase;
}
.text-color-green {
  color: #21bf73;
}
.text-label {
  color: #000;
  font-family: Spacegrotesk,sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: .15em;
}
.margin-box.top-12-bottom-40 {
  margin-bottom: 40px;
  margin-top: 12px;
}
.max-width._460 {
  max-width: 460px;
}
.max-width {
  width: 100%;
}
.text-size-medium {
  font-size: 16px;
  line-height: 130%;
}
.careers-culture-flex-images {
  height: 590px;
  position: relative;
  width: 500px;
}
.careers-culutre-image.big {
  border-radius: 20px;
  bottom: auto;
  left: 0;
  max-width: none;
  right: auto;
  top: 0;
}
.careers-culutre-image {
  bottom: auto;
  left: 428px;
  position: absolute;
  right: auto;
  top: 312px;
}
.careers-culutre-image._2 {
  left: 324px;
  top: 480px;
}
.careers-culutre-image {
  bottom: auto;
  left: 428px;
  position: absolute;
  right: auto;
  top: 312px;
}

.margin-box.top-12-bottom-40 {
  margin-bottom: 40px;
  margin-top: 12px;
}
.margin-box.top-70 {
  margin-top: 70px;
}
.text-style-caps {
  text-transform: uppercase;
}
.about-info-logo-flex {
  grid-column-gap: 48px;
  display: flex;
  margin-top: 16px;
}
.w-inline-block {
  display: inline-block;
  max-width: 100%;
}
.careers-culutre-image._3 {
  left: auto;
  right: 431px;
}
.careers-culutre-image.big._2 {
  border-radius: 15px;
  bottom: auto;
  left: auto;
  right: 0;
  top: 0;
}
.careers-culutre-image._4 {
  left: auto;
  right: 325px;
  top: 480px;
}
.careers-culutre-image {
  bottom: auto;
  left: 428px;
  position: absolute;
  right: auto;
  top: 312px;
  border-radius: 20px;
}
img {
  display: inline-block;
  max-width: 100%;
}

.showcase {
  width: 100%;
  height: 530px;
  position: relative;
  color: white;
  text-align: center;
  background: #9f91d1;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}
.showcase img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}
.showcase .overlay {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.show-c-tittle {
  margin-top: 100px;
}
.showcase p {
  margin-top: 1px;
  color: #fff;
  font-size: 15px;
}
.ourpatnerheadin {
  margin-bottom: unset !important;
  font-size: 16px !important;
  color: #21bf73 !important;
  font-weight: bold;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
}
.showcase h2 {
  font-size: 4em;
  margin-top: 20px;
  color: #fff;
  font-weight: bolder;
  font-family: 'Space Grotesk', sans-serif;
}
.img-show-row {
  margin: 50px auto;
}
.center-img {
  text-align: center;
  display: flex;
  opacity: 1;
  width: 100%;
  max-width: 218px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: center;
  display: flex;
  color: #fff;
}
.values-icon-wrapper {
  width: 72px;
  height: 72px;
  background-color: #8072d5;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.showcase p {
  margin-top: 1px;
  color: #fff;
  font-size: 15px;
}
.values-icon-wrapper img {
  width: 40px !important;
  height: 40px !important;
  position: unset !important;
  display: flex !important;
  text-align: center !important;
  align-items: center !important;
}
/* .image3 card{

} */
.abt_section_5_main {
  margin: 100px auto;
}
.ourpatnerheadin {
  margin-bottom: unset !important;
  font-size: 16px !important;
  color: #21bf73 !important;
  font-weight: bold;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
}
.abt-sec-5 h3 {
  font-size: 48px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
}
.abt-sec-5 p {
  font-size: 15.5px;
  color: gray;
}
.about-people-flex-right {
  grid-column-gap: 52px;
  grid-row-gap: 32px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.people-card-image {
  width: 151px;
  height: 151px;
  /* background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  margin-bottom: 12px;
}
.text-people-heading {
  margin-bottom: 4px;
  font-size: 16px !important;
  line-height: 110%;
  color: #000;
  font-weight: 600;
}
.text-people-caption {
  color: #515d6c;
  font-size: 12px !important;
}
.sub_title1 {
  font-size: 24px;
  font-weight: bold;
  color: #8072D5;
}
.ourcompany h2 {
  font-size: 48px;
  font-family: 'Space Grotesk', sans-serif;
  margin: 15px auto;
}
.second_heading{
  font-size: 48px;
  font-family: 'Space Grotesk', sans-serif;
  margin: 15px auto;
}
.memberof_point{
  margin-bottom: unset !important;
  font-size: 16px !important;
  color: #8072D5 !important;
  font-weight: bold;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;

}
.media-fea {
  background-color: #000;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
  padding-top: 88px;
  padding-bottom: 88px;
  position: relative;
}
.media-fea-cont {
  text-align: center;
}
.ourpatnerheadin {
  margin-bottom: unset !important;
  font-size: 16px !important;
  color:#ffffff !important;
  font-weight: bold;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
}
.media-fea-cont h2 {
  font-size: 4em;
  margin-top: 20px;
  color: #fff;
  font-weight: bolder;
  font-family: 'Space Grotesk', sans-serif;
}
.main_heading123{
font-size: 4em;
    margin-top: 20px;
    color: #fff;
    font-weight: bolder;
}
.sub_titlefeatureon {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.bgon5{
  background-color: #14161a;

}
.text-mutedon{
  white-space: normal;
  color: #ffffff;

}
.learnon{
  color: #8072D5 !important;
  font-weight: 700;
  line-height: 140%;

}
.Find_heading{
  font-weight: bold;
  font-size: 30px;
  margin-top: 15px;

}

/*slider*/ 
.about-career-flex {
  grid-column-gap: 20px;
  justify-content: center;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.about-career-image {
  flex: none;
}
.career-image-m {
  width: 200px;
}
.about-career-image.margin-top-40 {
  margin-top: 40px;
}

@media (max-width: 991px){
.careers-culture-flex-images {
    display: none;
}
}
.mob-img-abt-new {
  display: none;
}
@media (max-width: 991px){
.mob-img-abt-new {
    display: flex !important;
    gap: 20px;
}
}
@media screen and (max-width: 479px){
.container-medium {
    padding-left: 16px;
    padding-right: 16px;
}
}
@media screen and (max-width: 474px){
.about-people-flex-right {
    grid-column-gap: 45px;
    grid-row-gap: 32px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr !important;
    grid-auto-columns: 1fr;
    display: grid;
    justify-items: center;
    text-align: center;
}
}
@media (max-width: 991px){
.showcase {
    height: 700px !important;
}
}
@media (max-width: 991px){
.showcase h2 {
    font-size: 3em !important;
}
}
@media (max-width: 991px){
.showcase {
    height: 1140px !important;
    width: 412px;
}
.img-show-row {
  margin: 50px auto;
  margin-left: 75px;
}
.blurnone{
  display: none;
}
}

@media only screen and (max-width: 600px) {
  .about-career-flex{
    display: none;
  }
}
@media (max-width: 361px){
.showcase {
    height: 1140px !important;
    width: 367px;
}
}
@media (max-width: 361px){
.img-show-row {
    margin: 50px auto;
    margin-left: 49px;
}
}
@media screen and (max-width: 400px){
.main_heading123 {
    font-size: 2em;
}
}

@media only screen and (max-width: 361px){
.appstorage_heading {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 17px;
}
}
@media only screen and (max-width: 415px){
.appstorage_heading {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;
}
}

/* @media only screen and (max-width: 1460px) {
  .container-medium {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 79px;
    padding-right: 110px;
    width: 100%;
  }
} */